// import store from "@/state/store";

export default [
  {
    path: "/",
    name: "home",
    meta: {},
    component: () => import("./views/home"),
  },
  {
		path: '/500',
		name: '500',
		component : () => import('./views/utility/500')
	},
  {
		path: '/404',
		name: '404',
		component : () => import('./views/utility/404')
	},
  
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },

  {
		path: '/:catchAll(.*)',
		redirect: '404',
	},
];